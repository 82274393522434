import React from 'react';
import {useBreakpoints} from 'modern-famly';
import {useRouteMatch} from 'react-router-dom';

import {CardsContainer} from 'signin-app/groups/group-item';
import {useTypedSelector} from 'signin-app/components/hooks';
import {GroupItem} from 'signin-app/groups/group-item';

import * as Selectors from './selectors';

const Groups = () => {
    const match = useRouteMatch();
    const groups = useTypedSelector(Selectors.groups);
    const {isTabletPortraitAndLarger} = useBreakpoints();

    return (
        <CardsContainer>
            {groups.map(group => (
                <GroupItem
                    key={group.groupId}
                    title={group.title}
                    image={group.image}
                    href={`${match.url}/${group.groupId}`}
                    groupType={'Children'}
                    groupId={group.groupId}
                    alignment={isTabletPortraitAndLarger ? 'vertical' : 'horizontal'}
                />
            ))}
        </CardsContainer>
    );
};

export default Groups;
