import {useMutation} from '@apollo/client';
import React from 'react';
import {useDispatch} from 'react-redux';

import {updateError, updateSuccess} from 'signin-app/global-event/actions';
import {pinLogout} from 'signin-app/pin/actions';
import translate from 'signin-app/helpers/translate';

import {type RemoteOpenDoorMutation, type RemoteOpenDoorMutationVariables} from './__generated__/queries.api-types';
import * as Queries from './queries';

export const useRemoteOpenDoor = (
    siteId: string | undefined,
    loginId: string | undefined,
    expiration: string | undefined,
    hmac: string | undefined,
) => {
    const [openDoor] = useMutation<RemoteOpenDoorMutation, RemoteOpenDoorMutationVariables>(Queries.RemoteOpenDoor);
    const dispatch = useDispatch();

    return React.useCallback(async () => {
        if (!siteId || !loginId || !expiration || !hmac) {
            return;
        }
        // Initiate the open door mutation
        openDoor({
            variables: {
                siteId,
                loginId,
                expiration,
                hmac,
            },
            onError: error => {
                dispatch(updateError.action(error.message));
            },
            onCompleted: doorData => {
                dispatch(
                    updateSuccess.action(translate('doorIsOpen', {interval: doorData.doorLocking.openDoor.interval})),
                );
            },
        });

        // And log the user out immediately
        dispatch(pinLogout.action());
    }, [openDoor, siteId, loginId, dispatch, expiration, hmac]);
};
