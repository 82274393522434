import React from 'react';
import {Link} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {createStack, Text, Stack} from 'modern-famly';

import {useCustomWhitelabelColor} from 'signin-app/components/hooks/use-custom-whitelabel-color';

// Image used as a placeholder for groups, tags and institutions by the backend
const EMPTY_IMAGE_NAME = 'empty-group.jpg';

// eslint-disable-next-line no-restricted-syntax
export type GroupType = 'Children' | 'Staff';

interface GroupProps {
    title: string;
    href: string;
    checkedInCount?: number;
    groupType: GroupType;
    groupId: string;
    image?: string;
    imageSize?: ImageSize;
    className?: string;
    alignment?: 'vertical' | 'horizontal';
}

const StyledItemContainer = styled(
    createStack({
        width: '100%',
        px: {base: 4, tabletPortrait: 6},
        py: {base: 3, tabletPortrait: 4},
        gap: 2,
    }),
)<{selected?: boolean; customP200?: string; customP300?: string; alignment?: 'vertical' | 'horizontal'}>`
    box-sizing: border-box;
    background-color: ${props => props.theme.mf.colorPalette.n0};
    border: 1px solid ${props => props.theme.mf.colorPalette.n200};
    border-radius: ${props => props.theme.mf.spacing(4)};
    position: relative;
    cursor: pointer;
    align-items: ${props => (props.alignment === 'vertical' ? 'flex-start' : 'center')};

    ${props =>
        props.selected
            ? css`
                  border-color: ${props.customP300};
                  background-color: ${props.customP200};
              `
            : ''}
`;

const ImageContainer = styled(
    createStack({
        alignItems: 'center',
        justifyContent: 'center',
    }),
)<{color?: string; size?: ImageSize}>`
    width: ${props => (props.size === 'large' ? '72px' : '48px')};
    height: ${props => (props.size === 'large' ? '72px' : '48px')};
    border-radius: 50%;
    border: 1px solid white;
    box-sizing: border-box;
    flex-shrink: 0;
    font-size: 20px;
    ${props =>
        props.color
            ? css`
                  background: ${props.color};
              `
            : ''}
`;

const StyledTextContainer = styled(createStack({}))`
    max-width: 150px;
    * {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`;

const StyledImage = styled.img`
    width: 100%;
    border-radius: 50%;
`;

const StyledCount = styled(
    createStack({
        position: 'absolute',
        top: '0',
        right: '0',
        width: '24px',
        height: '24px',
        backgroundColor: 'b400',
        color: 'n0',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
    }),
)`
    transform: translate(9px, -9px);
    overflow: hidden;
`;

const StyledLink = styled(Link)`
    width: 100%;
`;

export const CardsContainer = styled(
    createStack({
        display: 'grid',
        gap: '16px',
        justifyContent: 'space-between',
    }),
)<{isCompact?: boolean}>`
    grid-template-columns: repeat(auto-fill, minmax(${props => (props.isCompact ? '200px' : '320px')}, 1fr));
`;

export const GroupItem: React.FC<GroupProps> = ({
    image,
    imageSize,
    title,
    href,
    checkedInCount,
    className,
    groupType,
    groupId,
    alignment,
}) => {
    const displayTitle = React.useMemo(() => {
        if (groupType === 'Children') {
            return title;
        }
        // If the number of employees is over a limit set by the backend,
        // we get multiple staff groups with names including a range of employee initials
        // in the given group, e.g. `Staff A-W`, `Staff W-Z` instead of just `Staff`.
        const [staffTitle, initials] = title.split(' ');
        return initials ? initials : staffTitle;
    }, [groupType, title]);

    return (
        <Stack>
            <StyledLink to={href} className={className}>
                <Stack position="relative" width="100%">
                    <Card
                        image={image}
                        imageSize={imageSize}
                        displayTitle={displayTitle}
                        color={getGroupColor(groupId)}
                        alignment={alignment}
                    />
                    {groupType === 'Children' && checkedInCount ? <StyledCount>{checkedInCount}</StyledCount> : null}
                </Stack>
            </StyledLink>
        </Stack>
    );
};

type ImageSize = 'regular' | 'large';

export const Card: React.FC<{
    image: string | undefined;
    imageSize?: ImageSize;
    displayTitle: string;
    initials?: string;
    color?: string;
    onClick?: VoidFunction;
    selected?: boolean;
    dataE2eCardContainerClass?: string;
    alignment?: 'vertical' | 'horizontal';
}> = ({image, imageSize, displayTitle, initials, color, onClick, selected, dataE2eCardContainerClass, alignment}) => {
    const hasImage = React.useMemo(() => image && !image.includes(EMPTY_IMAGE_NAME), [image]);
    const p200 = useCustomWhitelabelColor('p200');
    const p300 = useCustomWhitelabelColor('p300');

    const imageElement = React.useMemo(() => {
        if (hasImage) {
            return (
                <ImageContainer size={imageSize}>
                    <StyledImage src={image} />
                </ImageContainer>
            );
        }
        if (!hasImage && initials) {
            return <ImageContainer>{initials}</ImageContainer>;
        }
        if (!hasImage && color) {
            return <ImageContainer color={color} />;
        }
        return null;
    }, [hasImage, image, imageSize, initials, color]);

    return (
        <StyledItemContainer
            onClick={onClick}
            selected={selected}
            data-e2e-class={dataE2eCardContainerClass}
            customP200={p200}
            customP300={p300}
            alignment={alignment}
            flexDirection={alignment === 'vertical' ? 'column' : 'row'}
        >
            {imageElement}
            <StyledTextContainer>
                <Text variant="h6" emphasized color="n400">
                    {displayTitle}
                </Text>
            </StyledTextContainer>
        </StyledItemContainer>
    );
};

/*
|---------------------------------------------------------------------------------
| HELPERS
|---------------------------------------------------------------------------------
*/

// eslint-disable-next-line no-restricted-syntax
enum TextColors {
    White = '#FFFFFF',
    Black = '#120A20',
}

const GroupColors: {[backgroundColor: string]: {textColor: TextColors}} = {
    '#120A20': {textColor: TextColors.White},
    '#5C34A3': {textColor: TextColors.White},
    '#4FB97C': {textColor: TextColors.Black},
    '#B9EBD9': {textColor: TextColors.Black},
    '#243CC1': {textColor: TextColors.White},
    '#BD2A1F': {textColor: TextColors.White},
    '#FFB7D5': {textColor: TextColors.Black},
    '#D3D3D3': {textColor: TextColors.Black},
    '#6FC7EC': {textColor: TextColors.Black},
    '#FFCA3C': {textColor: TextColors.Black},
    '#F5BD97': {textColor: TextColors.Black},
};

// If a room doesn't have an image, we use a custom color for the room's background.
// The color is derived from the room's id.
const getGroupColor = (groupId: string) => {
    const colorsCount = Object.keys(GroupColors).length;
    const colorIndex = groupId.charCodeAt(0) % colorsCount;
    const [backgroundColor] = Object.entries(GroupColors)[colorIndex];

    return backgroundColor;
};
