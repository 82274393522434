import {createSelector} from 'reselect';

import SignInAppBehaviorIds from 'web-app/behaviors/signin-app-behavior-ids';
import {type RootState} from 'signin-app/redux/main-reducer';
import {WhitelabelUrl} from 'web-app/shared/white-labeling-constants';

import {
    makeEspiraConfiguration,
    makeFamlyConfiguration,
    makeKidoConfiguration,
    makeBrightHorizonsConfiguration,
    makeKindredConfiguration,
    makeMonkeyPuzzleConfiguration,
    makeGrandirUKConfiguration,
    makeNuffieldHealthConfiguration,
    makeNFamilyClubConfiguration,
    makeKinderzimmerUKConfiguration,
    makeCpcTreehouseConfiguration,
    makeOrchardConfiguration,
    makeBusyBeesConfiguration,
} from './configurations';
import {type WhiteLabelName} from './types';

const groupBehaviors = (state: RootState) => state.institutionOverview.behaviors;

const whiteLabelInfo = createSelector(
    groupBehaviors,
    behaviors =>
        (behaviors.behaviorForId(SignInAppBehaviorIds.WhiteLabelInfo)?.payload as {
            name: WhiteLabelName;
        }) ?? null,
);

// First look at the hostname, then look at the behavior's `name`.
const whiteLabelName = createSelector(whiteLabelInfo, whiteLabelInfo => {
    const currentHostname = window.location.hostname;

    if (currentHostname.endsWith(WhitelabelUrl.espira)) {
        return 'WHITE_LABEL_ESPIRA';
    } else if (currentHostname.endsWith(WhitelabelUrl.kido)) {
        return 'WHITE_LABEL_KIDO';
    } else if (currentHostname.endsWith(WhitelabelUrl.brighthorizons)) {
        return 'WHITE_LABEL_BRIGHT_HORIZONS';
    } else if (currentHostname.endsWith(WhitelabelUrl.kindred)) {
        return 'WHITE_LABEL_KINDRED';
    } else if (currentHostname.endsWith(WhitelabelUrl.monkeypuzzle)) {
        return 'WHITE_LABEL_MONKEY_PUZZLE';
    } else if (currentHostname.endsWith(WhitelabelUrl.grandiruk)) {
        return 'WHITE_LABEL_GRANDIR_UK';
    } else if (currentHostname.endsWith(WhitelabelUrl.nuffieldhealth)) {
        return 'WHITE_LABEL_NUFFIELD_HEALTH';
    } else if (currentHostname.endsWith(WhitelabelUrl.nfamilyclub)) {
        return 'WHITE_LABEL_N_FAMILY_CLUB';
    } else if (currentHostname.endsWith(WhitelabelUrl.kinderzimmeruk)) {
        return 'WHITE_LABEL_KINDERZIMMER_UK';
    } else if (currentHostname.endsWith(WhitelabelUrl.cpctreehouse)) {
        return 'WHITE_LABEL_CPC_TREEHOUSE';
    } else if (currentHostname.endsWith(WhitelabelUrl.cpcorchards)) {
        return 'WHITE_LABEL_CPC_ORCHARDS';
    } else if (currentHostname.endsWith(WhitelabelUrl.busybees)) {
        return 'WHITE_LABEL_BUSYBEES';
    } else if (currentHostname.endsWith('localhost')) {
        // USE FOR LOCAL TESTING
        return 'WHITE_LABEL_NUFFIELD_HEALTH';
    } else {
        return whiteLabelInfo?.name;
    }
});

export const whiteLabelConfiguration = createSelector(whiteLabelName, whiteLabelName => {
    switch (whiteLabelName) {
        case 'WHITE_LABEL_ESPIRA':
            return makeEspiraConfiguration();
        case 'WHITE_LABEL_KIDO':
            return makeKidoConfiguration();
        case 'WHITE_LABEL_BRIGHT_HORIZONS':
            return makeBrightHorizonsConfiguration();
        case 'WHITE_LABEL_KINDRED':
            return makeKindredConfiguration();
        case 'WHITE_LABEL_MONKEY_PUZZLE':
            return makeMonkeyPuzzleConfiguration();
        case 'WHITE_LABEL_GRANDIR_UK':
            return makeGrandirUKConfiguration();
        case 'WHITE_LABEL_NUFFIELD_HEALTH':
            return makeNuffieldHealthConfiguration();
        case 'WHITE_LABEL_N_FAMILY_CLUB':
            return makeNFamilyClubConfiguration();
        case 'WHITE_LABEL_KINDERZIMMER_UK':
            return makeKinderzimmerUKConfiguration();
        case 'WHITE_LABEL_CPC_TREEHOUSE':
            return makeCpcTreehouseConfiguration();
        case 'WHITE_LABEL_CPC_ORCHARDS':
            return makeOrchardConfiguration();
        case 'WHITE_LABEL_BUSYBEES':
            return makeBusyBeesConfiguration();
        default:
            return makeFamlyConfiguration();
    }
});
