import gql from 'graphql-tag';

export const RemoteOpenDoor = gql`
    mutation RemoteOpenDoor($siteId: SiteId!, $loginId: LoginId!, $expiration: Long!, $hmac: HMAC!) {
        doorLocking {
            openDoor(siteId: $siteId, loginId: $loginId, expiration: $expiration, hmac: $hmac) {
                interval
            }
        }
    }
`;
