import * as React from 'react';
import Autocomplete, {type AutocompleteRenderInputParams} from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import {IconAdornment, type SelectOption, type SelectProps} from 'modern-famly/components/input';
import {useTranslation} from 'modern-famly/system/use-translation';
import {type DataProps, useDataProps} from 'modern-famly/components/util';

import {
    AutocompleteOption,
    PopperComponent,
    componentsProps,
    sxAutocomplete,
    NUMBER_OF_OPTIONS_BEFORE_SCROLL,
} from './standalone-shared';
import type {CommonStandaloneSelectProps} from './standalone-shared';

export type Props = CommonStandaloneSelectProps & {
    /**
     * Selected value.
     */
    value?: SelectProps<SelectOption>['value'];

    /**
     * Callback fired when the value changes.
     */
    onChange: SelectProps<SelectOption>['onChange'];

    /**
     * A function that groups the select options into categories or sections.
     */
    groupBy?: SelectProps<SelectOption>['groupBy'];
} & DataProps;

export const StandaloneSelect = ({id, options, value: valueFromProps, onChange, size, groupBy, ...rest}: Props) => {
    const emptyResultsText = useTranslation('FilterAutocomplete.emptyResults');

    const dataProps = useDataProps(rest);

    const value = React.useMemo(() => {
        if (typeof valueFromProps === 'string') {
            // We return `null` rather than undefined because if the `value` is
            // provided that means that the component is used in controlled mode.
            return options.find(opt => opt.value === valueFromProps) ?? null;
        }

        return valueFromProps;
    }, [options, valueFromProps]);

    return (
        <Autocomplete
            id={id}
            open
            size={size}
            sx={sxAutocomplete}
            blurOnSelect
            componentsProps={componentsProps}
            disableCloseOnSelect
            value={value}
            onChange={(_, newValue) => {
                onChange?.(newValue ?? undefined, 'selectOption', undefined);
            }}
            PopperComponent={PopperComponent}
            renderTags={() => null}
            noOptionsText={emptyResultsText}
            renderOption={(props, option, {selected}) => {
                return <AutocompleteOption props={props} option={option} selected={selected} size={size} />;
            }}
            options={options}
            isOptionEqualToValue={(option, value) => option.value === (value?.value ?? value)}
            getOptionLabel={option => option.label}
            groupBy={groupBy}
            renderInput={params => {
                return (
                    <AutocompleteInput params={params} hideInput={options.length <= NUMBER_OF_OPTIONS_BEFORE_SCROLL} />
                );
            }}
            {...dataProps}
        />
    );
};

type AutocompleteInputProps = {
    params: AutocompleteRenderInputParams;
    hideInput: boolean;
};

const AutocompleteInput = ({params, hideInput}: AutocompleteInputProps) => {
    const placeholderText = useTranslation('FilterAutocomplete.placeholder');

    return (
        <Box marginBottom={2} hidden={hideInput}>
            <TextField
                {...params}
                autoFocus
                placeholder={placeholderText}
                variant="outlined"
                InputProps={{
                    ...params.InputProps,
                    startAdornment: <IconAdornment icon="search" size="regular" position="start" />,
                    endAdornment: undefined,
                }}
                fullWidth
            />
        </Box>
    );
};
