const institutionBehaviorIds = {
    ShowInstitutionDetailsContactPerson: 'ShowInstitutionDetailsContactPerson',
    ShowInstitutionDetailsEmail: 'ShowInstitutionDetailsEmail',
    ShowInstitutionDetailsVat: 'ShowInstitutionDetailsVat',
    ShowInstitutionDetailsEan: 'ShowInstitutionDetailsEan',
    CanViewClosingDays: 'CanViewClosingDays',
    ShowInquiriesApp: 'ShowInquiriesApp',
    CanSetupGroupRules: 'CanSetupGroupRules',
    CanEditOpeningHours: 'CanEditOpeningHours',
    CanSetClosingDays: 'CanSetClosingDays',
    CanUseVolumePricing: 'CanUseVolumePricing',
    ShowOccupancyPlanner: 'ShowOccupancyPlanner',
    CanEditAllInstitutionRoles: 'CanEditAllInstitutionRoles',
    EnableDirectDebit: 'EnableDirectDebit',
    ShowVacationPlanningApp: 'ShowVacationPlanningApp',
    ShowParentPermissionApp: 'ShowParentPermissionApp',
    ShowDocumentsApp: 'ShowDocumentsApp',
    ShowSignInApp: 'ShowSignInApp',
    ShowRoomPlanningApp: 'ShowRoomPlanningApp',
    ShowAttendanceReportApp: 'ShowAttendanceReportApp',
    ShowAttendanceStatistics: 'ShowAttendanceStatistics',
    ShowChildDevelopmentApp: 'ShowChildDevelopmentApp',
    CanManageFinancialAndOccupancySettings: 'CanManageFinancialAndOccupancySettings',
    ShowExpectedChildren: 'ShowExpectedChildren',
    CanManageKibigWeb: 'CanManageKibigWeb',
    CanEditInstitutionSettings: 'CanEditInstitutionSettings',
    ShowAccountingSettings: 'ShowAccountingSettings',
    CanUpdateMealTypes: 'CanUpdateMealTypes',
    CanManageInstitutionRoles: 'CanManageInstitutionRoles',
    CanAccessInstitution: 'CanAccessInstitution',
    CanCreateEmployee: 'CanCreateEmployee',
    CanCreateChild: 'CanCreateChild',
    CanInviteParents: 'CanInviteParents',
    CanCreateGroup: 'CanCreateGroup',
    ShowPlansApp: 'ShowPlansApp',
    ShowBookingsApp: 'ShowBookingsApp',
    ShowBookingsSettings: 'ShowBookingsSettings',
    ShowBookingsPlans: 'ShowBookingsPlans',
    ShowBookingsPurchases: 'ShowBookingsPurchases',
    ShowHomepageApp: 'ShowHomepageApp',
    CanAddMultipleFoodItems: 'CanAddMultipleFoodItems',
    CanAddWeekendMeals: 'CanAddWeekendMeals',
    CanViewInstitutionCalendar: 'CanViewInstitutionCalendar',
    CanCreateEventInInstitution: 'CanCreateEventInInstitution',
    CanEditPrivacyLink: 'CanEditPrivacyLink',
    ShowMealPlanningApp: 'ShowMealPlanningApp',
    ShowFamlyReportApp: 'ShowFamlyReportApp',
    ShowListFilterApp: 'ShowListFilterApp',
    CanUseAgeGroupCapFactor: 'CanUseAgeGroupCapFactor',
    CanListDirectDebitFiles: 'CanListDirectDebitFiles',
    CanManageGenericFunding: 'CanManageGenericFunding',
    CanViewProgressReport: 'CanViewProgressReport',
    ShowChildDevelopmentCohortComparison: 'ShowChildDevelopmentCohortComparison',
    ShowActivityLibrary: 'ShowActivityLibrary',
    ShowInstitutionChart: 'ShowInstitutionChart',
    CanManageCustomStatuses: 'CanManageCustomStatuses',
    CanManageAgeGroups: 'CanManageAgeGroups',
    CanCreateInstitutionRoles: 'CanCreateInstitutionRoles',
    ShowGroupChart: 'ShowGroupChart',
    ShowSignInButton: 'ShowSignInButton',
    ShowSwitchRoomButton: 'ShowSwitchRoomButton',
    ShowChildSickButton: 'ShowChildSickButton',
    CanUseDiscountPresets: 'CanUseDiscountPresets',
    ShowWeekendsAsNotAttending: 'ShowWeekendsAsNotAttending',
    CanViewFundingGrants: 'CanViewFundingGrants',
    CanListDeposits: 'CanListDeposits',
    CanManagePaymentsAccount: 'CanManagePaymentsAccount',
    CanAddDiaperToiletAccident: 'CanAddDiaperToiletAccident',
    CanSendPaymentReminders: 'CanSendPaymentReminders',
    EnableSafeguarding: 'EnableSafeguarding',
    CanSupportAutomaticPayments: 'CanSupportAutomaticPayments',
    UseAdvancedPermissions: 'UseAdvancedPermissions',
    CanSetAccountForSessionsAndProducts: 'CanSetAccountForSessionsAndProducts',
    ShowStaffQualifications: 'ShowStaffQualifications',
    FundingWithBillPayer: 'FundingWithBillPayer',
    CanCheckChildrenInOrOut: 'CanCheckChildrenInOrOut',
    ShowBillPayerBankDetails: 'ShowBillPayerBankDetails',
    ShowBradfordFactor: 'ShowBradfordFactor',
    CanEditInstitutionRoles: 'CanEditInstitutionRoles',
    ShowFamlyPayApp: 'ShowFamlyPayApp',
    ShowReportedToForAccidentReports: 'ShowReportedToForAccidentReports',
    ShowImportModule: 'ShowImportModule',
    CanExportFromKitaPlaner: 'CanExportFromKitaPlaner',
    CanImportFromKivan: 'CanImportFromKivan',
    RequireSwissInvoicingData: 'RequireSwissInvoicingData',
    ShowBillPayerSensitiveInfo: 'ShowBillPayerSensitiveInfo',
    ShowAllergyAndDietConsiderations: 'ShowAllergyAndDietConsiderations',
    ShowInvoiceSettingsOrganizationNo: 'ShowInvoiceSettingsOrganizationNo',
    CanCreatePayment: 'CanCreatePayment',
    CanManageBillPayerReferences: 'CanManageBillPayerReferences',
    CanCreateBillPayer: 'CanCreateBillPayer',
    CanManageCheckinStatements: 'CanManageCheckinStatements',
    CanSeeCheckinStatements: 'CanSeeCheckinStatements',
    CanScanDocuments: 'CanScanDocuments',
    CanManageGlobalSettings: 'CanManageGlobalSettings',
    ShowGlobalSettings: 'ShowGlobalSettings',
    CanViewPaymentReminders: 'CanViewPaymentReminders',
    CanManagePaymentReminderTemplates: 'CanManagePaymentReminderTemplates',
    ShowLessonPlansApp: 'ShowLessonPlansApp',
    ShowMissingIntegrationWarning: 'ShowMissingIntegrationWarning',
    CanImportPayments: 'CanImportPayments',
    CanOptInPaymentImports: 'CanOptInPaymentImports',
    CanUsePaymentImportsEditModal: 'CanUsePaymentImportsEditModal',
    CanUseXeroPaymentImports: 'CanUseXeroPaymentImports',
    CanUsePlans: 'CanUsePlans',
    CanManageLoginValidation: 'CanManageLoginValidation',
    ShowMealRegistrationInOverview: 'ShowMealRegistrationInOverview',
    CanEditTag: 'CanEditTag',

    // Child development
    CanSeeStatuses: 'CanSeeStatuses',
    CanSeeVariants: 'CanSeeVariants',
    ShowTwoYearProgress: 'ShowTwoYearProgress',
    CanViewAdminReport: 'CanViewAdminReport',
    CanAddObservation: 'CanAddObservation',
    CanAddAssessment: 'CanAddAssessment',
    CanAddTwoYearProgress: 'CanAddTwoYearProgress',
    UseObservationVersion: 'UseObservationVersion',
    CanManageEYFS2021ChildDevelopment: 'CanManageEYFS2021ChildDevelopment',
    CanManageFrameworkSettings: 'CanManageFrameworkSettings',
    CanViewCurriculumBuilder: 'CanViewCurriculumBuilder',
    CanViewWhatsNext: 'CanViewWhatsNext',

    // Sibling linking
    CanAddInstitutionSiblings: 'CanAddInstitutionSiblings',
    CanAddOrganizationSiblings: 'CanAddOrganizationSiblings',
    CanAddExternalSiblings: 'CanAddExternalSiblings',

    // Health and safety app
    ShowHealthAndSafetyApp: 'ShowHealthAndSafetyApp',

    // External sharing options
    CanManageFacebookIntegration: 'CanManageFacebookIntegration',
    CanPublishToFacebook: 'CanPublishToFacebook',
    CanManageRssIntegration: 'CanManageRssIntegration',
    CanPublishToRss: 'CanPublishToRss',

    // Staffing
    StaffingWeekendSupport: 'StaffingWeekendSupport',

    // Media tagging flow
    CanManageMediaTagFlow: 'CanManageMediaTagFlow',
    ShouldFollowTaggingFlow: 'ShouldFollowTaggingFlow',

    // Settings
    CanManageLearningFrameworks: 'CanManageLearningFrameworks',
    CanManageSocialMediaLinks: 'CanManageSocialMediaLinks',
    CanManageSignInPinCodeRequiredForSignInApp: 'CanManageSignInPinCodeRequiredForSignInApp',
    MealItemsInSettings: 'MealItemsInSettings',
    CanManageAutoLogout: 'CanManageAutoLogout',
    CanManageCurriculums: 'CanManageCurriculums',

    // Famly Store
    ShowFamlyStore: 'ShowFamlyStore',
    ShowPartnersPage: 'ShowPartnersPage',
    ShowEmbedPackagePricingPage: 'ShowEmbedPackagePricingPage',

    // Account settings
    CanSetOutOfOfficeStatus: 'CanSetOutOfOfficeStatus',

    // Finance reports
    ShowRevenueAndDebtApp: 'ShowRevenueAndDebtApp',
    ShowRevenueApp: 'ShowRevenueApp',
    ShowDebtApp: 'ShowDebtApp',
    ShowFinancesInvoicingApp: 'ShowFinancesInvoicingApp',
    ShowFinancesPaymentsApp: 'ShowFinancesPaymentsApp',
    ShowOccupancyReportApp: 'ShowOccupancyReportApp',
    CanViewBillPayersReport: 'CanViewBillPayersReport',
    CanViewInvoicesReport: 'CanViewInvoicesReport',
    CanViewRevenueReport: 'CanViewRevenueReport',

    // Bookings and Finance
    AddressFormatting: 'AddressFormatting',
};

export default institutionBehaviorIds;
