import React from 'react';
import {type RouteComponentProps} from 'react-router-dom';
import {Box} from 'modern-famly';
import {useDispatch} from 'react-redux';

import {checkInChild} from 'signin-app/child/check-in/actions';
import * as ChildCheckinSelectors from 'signin-app/child/check-in/selectors';
import {pinHolder} from 'signin-app/pin/selectors';
import translate from 'signin-app/helpers/translate';
import PersonButton from 'signin-app/components/person-button';
import {
    ConfirmButton,
    ActionButton as CancelButton,
    BottomBarContainer,
    PageBodyContainer,
} from 'signin-app/components/action-buttons';
import {selectGoHomeWithChild} from 'signin-app/go-home-with/actions';
import {getPinChild} from 'signin-app/child/selectors';
import {useTypedSelector} from 'signin-app/components/hooks';
import {GroupGrid} from 'signin-app/components/group-grid';
import {useDisplayName} from 'signin-app/helpers/use-display-name';

import * as Selectors from './selectors';

interface ChildrenProps {
    childId: string;
}

type InputProps = RouteComponentProps<{groupId: string}> & ChildrenProps;

const Children: React.FC<InputProps> = props => {
    const {match} = props;
    const groups = useTypedSelector(Selectors.groups);
    const selectedGoHomeWithChildId = useTypedSelector(Selectors.selectedGoHomeWithChildId);
    const isCheckingIn = useTypedSelector(ChildCheckinSelectors.isCheckingIn);
    const pin = useTypedSelector(pinHolder);
    const selectedChild = useTypedSelector(state => getPinChild(state, {childId: props.childId}));
    const displayName = useDisplayName(selectedChild?.name.firstName, selectedChild?.name.lastName);

    const selectedGroup = groups.find(group => group.groupId === match.params.groupId);
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(selectGoHomeWithChild.action(''));
    }, [dispatch]);

    const handleChildCheckin = React.useCallback(() => {
        if (!selectedChild) {
            return;
        }
        const successMessage = translate('signInSuccess', {
            personName: displayName,
        });

        dispatch(
            checkInChild.action({
                childId: selectedChild.id,
                successMessage,
                pin,
                goHomeWithChildId: selectedGoHomeWithChildId,
            }),
        );
    }, [selectedChild, dispatch, selectedGoHomeWithChildId, pin, displayName]);

    return (
        <Box>
            <PageBodyContainer>
                <GroupGrid>
                    {selectedGroup
                        ? selectedGroup.children.map(child => (
                              <PersonButton
                                  key={child.childId}
                                  person={child}
                                  onClick={selectGoHomeWithChild.dispatch}
                                  selected={selectedGoHomeWithChildId === child.childId}
                              />
                          ))
                        : null}
                </GroupGrid>
            </PageBodyContainer>
            <BottomBarContainer>
                <CancelButton text={translate('cancel')} link="/pin" />
                <ConfirmButton
                    type={'signIn'}
                    isLoading={isCheckingIn}
                    onClick={handleChildCheckin}
                    text={translate('signIn', {personName: displayName})}
                    disabled={!selectedGoHomeWithChildId}
                />
            </BottomBarContainer>
        </Box>
    );
};

export default Children;
